import React from "react";
import NewHeader from "../components/newHeader";
import { useLocation } from "react-router-dom";
import BlueButton from "../../Component/UI/BlueButton";
import { Col, Container, Row } from "react-bootstrap";
import { CoachIcon, Menplayer } from "../../assets";
import moment from "moment";
import {
  LeadingActions,
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
} from "react-swipeable-list";
import "react-swipeable-list/dist/styles.css";

const Appliedplayers = () => {
  const location = useLocation();

  const AppliedplayersList = location?.state?.data?.profilesApplied;

  console.log(AppliedplayersList, "AppliedplayersList");

  const handleSwipe = (item) => {
    console.log("calling swipe action for:", item);
  };

  const leadingActions = () => (
    <LeadingActions>
      <SwipeAction onClick={() => console.info("swipe action triggered")}>
      Details
      </SwipeAction>
    </LeadingActions>
  );

  const trailingActions = () => (
    <TrailingActions>
      <SwipeAction
        // destructive={true}
        onClick={() => console.info("swipe action triggered")}
      >
        Details
      </SwipeAction>
    </TrailingActions>
  );

  const UserComponent = () => {
    return (
      <div className="coachJobs coachContainer">
        <NewHeader name={"Players applied"} path="/" />

        <div className="coachJobFilter">
          <div className="filters">
            <BlueButton active={true}>All</BlueButton>
            <BlueButton>Open</BlueButton>
            <BlueButton>Closed</BlueButton>
          </div>
        </div>
        <div style={{ display: "flex", gap: "20px", overflowX: "auto" }}></div>
      </div>
    );
  };

  return (
    <Container
      className="create-account-container profileCoach coachJobs"
      style={{
        paddingBottom: "150px",
        paddingLeft: "0px",
        paddingRight: "0px",
      }}
    >
      <UserComponent />

      <div style={{ marginTop: "20px" }}>
        {AppliedplayersList &&
          AppliedplayersList.map((item, index) => {
            return (
              <div style={{ marginTop: "20px" }} key={index}>
                <SwipeableList>
                  <SwipeableListItem
                    leadingActions={leadingActions()}
                    trailingActions={trailingActions()}
                  >
                    <div
                      className="experiences"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        src={item?.profile?.profileImage || Menplayer}
                        alt="Profile"
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                        }}
                      />
                      <div style={{ marginLeft: "20px" }}>
                        <h2>{item?.profile?.title}</h2>
                        <p>
                          {moment(item?.startDate).format("MMM YYYY")}
                           {/* -{" "}
                          {item?.currentlyWorking
                            ? "Present"
                            : moment(item?.endDate).format("MMM YYYY")} */}
                        </p>
                      </div>
                    </div>
                    <hr />
                  </SwipeableListItem>
                </SwipeableList>
              </div>
            );
          })}
      </div>
    </Container>
  );
};

export default Appliedplayers;
