import React, { useState, useEffect } from "react";

// Components

import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

// Packages

import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

// Media

import { BASE_URL, BASE_URL_ADMIN } from "../../../Utils/utils";
import BackgroundCard from "../../../Component/UI/BackgroundCard";
import AthleatHeader from "../../components/Header/AthleatHeader";
import ProfileArea from "../../../Component/UI/ProfileArea";
import moment from "moment";
import { emailIcon, instagramIcon, Menplayer } from "../../../assets";
import "./NewTeamById.css";

// Global Variables
const API_ENDPOINT = BASE_URL;

export default function NewTeamById() {
  const navigate = useNavigate();

  const team_id = useParams();
  const [Rosters, setRosters] = useState(null);
  const [team, setTeam] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchLeagueData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${BASE_URL_ADMIN}team/team/${team_id?.id}`
        );
        const data = await response.json();
        setTeam(data.data);
        setLoading(false);
      } catch (error) {
        console.log(error.message);
        setLoading(false);
      }
    };

    if (team_id?.id) {
      fetchLeagueData();
    }
  }, [API_ENDPOINT, team_id]);

  useEffect(() => {
    const fetchRostersData = async () => {
      setLoading(true);

      try {
        const response = await fetch(`${BASE_URL_ADMIN}roster/rosters`);
        const data = await response.json();
        setRosters(data?.data?.slice(0, 6));
        setLoading(false);
      } catch (error) {
        console.log(error.message);
        setLoading(false);
      }
    };

    fetchRostersData();
  }, [API_ENDPOINT]);

  return (
    <>
      {loading ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <>
          <BackgroundCard>
            <AthleatHeader name={"Team"} />
            <ProfileArea image={team?.image} name={team?.name || ""} />
          </BackgroundCard>

          <div className="NewTeamById">
            <hr />
            <div className="socialIcon">
              <a href={team?.instagram} target="_blank">
                <img src={instagramIcon} alt="instagramIcon" />
              </a>
              <a
                href={`mailto:${team?.email}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={emailIcon} alt="emailIcon" />
              </a>
            </div>
            <hr />
            <div className="TeamDetails">
              <div style={{ width: "30%" }} className="TeamDetailBox">
                <p>Gender</p>
                <h5>{team?.gender}</h5>
              </div>

              <div style={{ width: "40%" }} className="TeamDetailBox">
                <p>Location</p>
                <h5>{team?.country}</h5>
              </div>

              <div style={{ width: "30%" }} className="TeamDetailBox">
                <p>League</p>
                <h5>{team?.league?.name}</h5>
              </div>
            </div>

            <div className="NewTeamByIdDescription">
              <h2>Scoutz Scouting Report</h2>
              <p>{team?.description}</p>
            </div>

            <div className="NewTeamByIdRosterArea">
              <div className="header">
                <h2>Current Roster</h2>
                <p onClick={() => navigate("/rosters/overview")}>See All</p>
              </div>
              {Rosters?.length > 0 &&
                Rosters?.map((item, index) => {
                  return (
                    <div style={{ marginTop: "20px" }} key={index} xs={12}>
                      <div className="experiences">
                        <img src={item?.image || Menplayer} alt="$$$" />
                        <div>
                          <h2>{item?.name}</h2>
                          <p>6’8 • PG • </p>
                        </div>
                      </div>
                      <hr />
                    </div>
                  );
                })}
            </div>
          </div>
        </>
      )}
    </>
  );
}
